import React from "react";
import styled from "styled-components";
import { Parallax } from 'react-scroll-parallax';

import H from "../text/H";
import P from "../text/P";
import { breakPoints, corporatePalette } from "../../misc/consts";
import House from '../../images/icons/bighouse.inline.svg';
import Pie from '../../images/icons/bigpie.inline.svg';
import Keys from '../../images/icons/bigkeys.inline.svg';
import { StaticImage } from "gatsby-plugin-image";

const Container = styled.div`
  position: relative;

  .overlay2 {
    position: relative;
    height: 400px;

    ${breakPoints.desktop} {
      height: 400px;
    }
  }
  .overlay2::after{
    content: "";
    background-color: rgba(25,25,25,0.75);
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    top: 0;
  }

  .cards {
    flex-direction: column;
    gap: 16px;
    ${breakPoints.desktop} {
      flex-direction: row;
      gap: 16px;
    }
    ${breakPoints.largeDesktop} {
      gap: 32px;
    }
  }

  .card {
    padding: 30px 20px;
    align-items: flex-start;
    text-align: left;
    p { text-align: left }

    ${breakPoints.largeDesktop} {
      padding: 45px 20px;
      align-items: center;
      text-align: center;
      p { text-align: center }
    }
  }

  .icon-cont {
    align-self: flex-start;
    width: 40px;
    height: 40px;
    margin-bottom: 12px;

    ${breakPoints.largeDesktop} {
      align-self: center;
      width: 110px;
      height: 100px;
      margin-bottom: 24px;
    }
  }
  .icon-wide {
    width: 60px;
    ${breakPoints.largeDesktop} {
      width: 150px;
    }
  }
  .icon {
    width: 100%;
    height: 100%;
  }
`;

function HowSection() {
  return (
    <Container>
      <div style={{ position: 'absolute', width: '100%', zIndex: -1 }}>
        <Parallax speed={-10}>
        <StaticImage
          src="../../images/corporate/how-work-bg.jpg"
          alt="le bedroom"
          quality={100}
          className="overlay2"
        />
        </Parallax>
      </div>
      <div className="marginal">
        <div className="full-width mb-xxl" />
        <div className="full-width mb-xxl" />
        <H className="fs-9 h-underline center-self" color={corporatePalette.almostWhite}>¿Cómo funciona?</H>
        <div className="full-width mb-lg" />

        <div className="cards shadow-partner">
          <div className="card shadow-1 corp-shadow-sm-hov flx1">
            <div className="icon-cont icon-wide">
              <House className="icon" />
            </div>
            <H className="fs-3 mb-md" color={corporatePalette.sand}>
              Descubrimos Ubicaciones Excepcionales
            </H>
            <P className="fs-2-66">
              En Ancana seleccionamos las mejores ubicaciones en los destinos más exclusivos y las ponemos a tu disposición completamente amuebladas y equipadas.
            </P>
          </div>
          <div className="card shadow-1 corp-shadow-sm-hov white-bg flx1">
            <div className="icon-cont">
              <Pie className="icon" />
            </div>
            <H className="fs-3 mb-md" color={corporatePalette.sand}>
              La dividimos y vendemos en fracciones
            </H>
            <P className="fs-2-66">
              Creamos una oportunidad única para los co-dueños al dividir la propiedad en fracciones. A través de este vehículo, gestionamos y administramos la propiedad para asegurar una experiencia sin complicaciones.
            </P>
          </div>
          <div className="card shadow-1 corp-shadow-sm-hov white-bg flx1">
            <div className="icon-cont">
              <Keys className="icon" />
            </div>
            <H className="fs-3 mb-md" color={corporatePalette.sand}>
              Vive una Experiencia Integral
            </H>
            <P className="fs-2-66">
              Vive una experiencia llave en mano con una gestión equivalente a un hotel de 5 estrellas. Aprovecha la apreciación de la propiedad y accede a un exclusivo intercambio con más de 15,000 propiedades en 120+ países.
            </P>
          </div>
        </div>
        <div className="full-width mb-lg" />
      </div>
    </Container>
  )
}

export default HowSection;
