import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import Ancana from '../../images/icons/ancana.inline.svg';

import { corporatePalette } from "../../misc/consts";

const Container = styled.div`
  z-index: 1;
  background-color: ${corporatePalette.blue};
  color: white;
  width: 100%;

  a {
    color: white;
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }
`;

function Footer({ scrollTo }) {
  return (
    <Container>
      <div className="h-stack-strict full-width marginal">
        <div className="flx2 tablet-and-up">
          <Ancana className="mb-lg" style={{ width: 145, height: 27 }} />
        </div>
        <div className="flx1">
          <p className="fs-3">SECCIONES</p>
          <a className="fs-2-33 fw-200 mb-md link" onClick={() => scrollTo('hero')}>Inicio</a>
          <a className="fs-2-33 fw-200 mb-md link" onClick={() => scrollTo('property')}>Propiedades</a>
          <a className="fs-2-33 fw-200 mb-md link" onClick={() => scrollTo('fractional')}>¿Cómo funciona?</a>
        </div>
        <div className="flx1">
          <p className="fs-3">CONTACTO</p>
          <a className="fs-2-33 fw-200 mb-md">+52 55 8993 8510</a>
          <a className="fs-2-33 fw-200 mb-md">
            <a href="mailto:info@ancana.co?subject=Quiero recibir más información sobre Ancana">hola@ancana.co</a>
          </a>
        </div>
        <div className="flx1">
          <p className="fs-3">LEGAL</p>
          <a className="fs-2-33 fw-200 mb-md" href="https://ancana.co/privacy-policy">Políticas de privacidad</a>
          {/* <a className="fs-2-33 fw-200 mb-md">Términos y condiciones</a> */}
        </div>
      </div>
      <div className="marginal">
        <div className="flx3 mobile-only center-self">
          <Ancana className="mb-lg" style={{ width: 145, height: 27 }} />
        </div>
        <p className="fs-2 fw-200 center-self">Todos los derechos reservados</p>
      </div>
    </Container>
  )
}

export default Footer;
