import './i18n';
import '../components/layout.css';

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ParallaxProvider } from 'react-scroll-parallax';
import { Script } from "gatsby";

import {
  HeroBannerSection,
  PropertiesSection,
  LoremSection,
  FractionalSection,
  BenefitsSection,
  HowSection,
  WhyAncanaSection
} from "../components/sectionsV3";
import ContactSection from "../components/sectionsV3/ContactSection";
import FaqSection from "../components/sectionsV3/FaqSection";
import Footer from "../components/sectionsV3/Footer";
import GallerySection from "../components/sectionsV3/GallerySection";
import MentionsSection from "../components/sectionsV3/MentionsSection";
import NavBar from "../components/sectionsV3/NavBar";
import { scrollToSection } from "../misc/utils";
import { Helmet } from "react-helmet";
import { adminApi } from "../api/ancanaAPI";
import ContactModal from "../components/ContactModal";
import useObserveRef from "../hooks/useObserveRef";


const Container = styled.div`
  overflow-x: hidden;
  // background-color: #FDFBF7;

  .ancana-logo {
    width: 138px;
  }
`;

const mockedListings = [
  {
    "id": 38,
    "name": "Adora Tulum 212",
    "slug": "adora-tulum-212",
    "amenities": [
      6,
      38,
      8,
      10,
      48,
      49,
      53,
      55,
      28,
      29,
      30,
      27,
      60
    ],
    "images": [
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/Terraza_2/4ec7d851827b9473d2c83a1d0dc388d6.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/INTERIORES-04824/92b003ec251eb2ade48f592e4a3d6cdc.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/INTERIORES-04718/abd35fee276a719a86be2af4bb5120b8.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/Cocina_Sala/f3c7977844b7db9698d1915f5d279fb5.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/INTERIORES-04792/0d53fca6d6a65bd30aaae686584edd17.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/Recamara_2/b93b8d7a613406217d8bdf2522e36a62.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/magicmike-05891/34b9fd07b103392f92f3d4360202f940.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/INTERIORES-04787/ea15f9656cb527584a4282022c716580.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/DSC08624/175aa397cd79b98e9f119e402593f7f5.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/INTERIORES-04958/c551f33075c0b1ba8b3ef3dc0ae73f75.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/DSC08657/2cd8cc90e9855ba1d709fa324bcad5e5.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/INTERIORES-04945/e8da0382e91d64efee835f8b735b03e7.webp"
    ],
    "files": [
      {
        "id": 46,
        "file": "https://api-v1.s3.amazonaws.com/files/ADORA_Tulum_212_Ancana_Brochure.pdf",
        "name": ""
      }
    ],
    "lat": "20.189730",
    "lng": "-87.462080",
    "address": "Av. Kukulkan Lt. 185, 77780 Tulum, Q.R.",
    "type": "unit",
    "published": true,
    "status": "available_now",
    "location": 4,
    "price": {
      "amount": undefined,
      "currency": "MXN"
    },
    "characteristics": {
      "square_meters": 133.0,
      "bedrooms": 2.0,
      "bathrooms": 2.0,
      "fractions": 8,
      "weeks_available": 6
    },
    "description_en": "Immerse yourself in the refined charm of Adora, a dream residence located in the heart of Tulum, where sophistication merges with nature in perfect harmony. Explore an oasis of endless possibilities, where every detail has been meticulously designed to offer an unparalleled living experience.\n\nFrom the impressive temazcal to the organic saltwater pool and the underground water passage, Adora invites you to connect with the energy of the surroundings and awaken your senses. Contemplate the vastness of the Riviera Maya from the majestic Yoga Tower, where you can vibe to the natural rhythm of the elements around you. Indulge in unforgettable moments at Adora, where you can immerse yourself in the spectacular cenote and rejuvenate your spirit in the temazcal. Experience unique rituals and connect with the essence of nature in these extraordinary spaces, designed to elevate your senses and provide a truly immersive experience in Tulum.\n\nWith a premium location on the area's most important avenue, Adora offers unique amenities in the market and high-end, sustainable construction materials. From its comprehensive energy management system to its commitment to social responsibility and the environment, Adora redefines the standard of luxury in Tulum. It's time to project your desires and awaken your deepest emotions through a space that inspires your senses.\n\nDiscover your own unique experience at Adora and make Tulum the backdrop for your most extraordinary dreams!",
    "description_es": "Sumérgete en el refinado encanto de Adora, una residencia de ensueño ubicada en el corazón de Tulum, donde la sofisticación se fusiona con la naturaleza en un equilibrio perfecto. Explora un oasis de posibilidades infinitas, donde cada detalle ha sido cuidadosamente diseñado para ofrecer una experiencia de vida inigualable.\n\nDesde el impresionante temazcal hasta la piscina orgánica salada y el pasaje acuático subterráneo, Adora te invita a conectar con la energía del entorno y despertar tus sentidos. Contempla la inmensidad de la Riviera Maya desde la majestuosa Yoga Tower, donde podrás vibrar al ritmo natural de los elementos que te rodean. Disfruta de momentos inolvidables en Adora, donde puedes sumergirte en el espectacular cenote y rejuvenecer tu espíritu en el temazcal. Experimenta rituales únicos y conecta con la esencia de la naturaleza en estos espacios extraordinarios, diseñados para elevar tus sentidos y brindarte una experiencia verdaderamente inmersiva en Tulum.\n\nCon una ubicación premium en la avenida más importante de la zona, Adora ofrece amenidades únicas en el mercado y materiales de construcción de alta gama y sustentables. Desde su sistema integral de manejo de energía hasta su compromiso con la responsabilidad social y el medio ambiente, Adora redefine el estándar de lujo en Tulum. Es el momento de proyectar tus deseos y despertar las emociones más profundas a través de un espacio que inspira tus sentidos. \n\n¡Descubre tu propia experiencia única en Adora y haz de Tulum el escenario de tus sueños más extraordinarios!",
    "unit": 43,
    "design": null,
    "development": null,
    "cover_image": "https://api-v1.s3.amazonaws.com/CACHE/images/images/Terraza_2/5f63471caaf820d7c40e9951632858a9.webp",
    "sort_index": 0
  }
];

const V2Page = ({ pageContext }) => {
  const [listings, setListings] = useState(mockedListings);
  const containerRef = useRef(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const [alreadyPopedup, setAlreadyPopedup] = useState(false);
  const alreadyPopedupRef = useRef(alreadyPopedup);

  const openPopup = () => {
    if(!alreadyPopedupRef.current) {
      setPopupVisible(true);
      // alreadyPopedupRef.current = true;
      setAlreadyPopedup(true);
    }
  }

  const { observedRef } = useObserveRef(alreadyPopedup, openPopup);

  const scrollTo = (name, offset = 0, cancelPopup = true) => {
    if (cancelPopup) setAlreadyPopedup(true);
    scrollToSection(name, containerRef, offset);
  }

  useEffect(() => {
    adminApi.get('listings/previews/?page_size=8&published=true&location_slug=tulum').then(res => {
      setListings(res.data.results);
    });
    const timeout = setTimeout(() => openPopup(), 15000);
    return () => {
      clearTimeout(timeout);
    }
  }, []);

  useEffect(() => {
    alreadyPopedupRef.current = alreadyPopedup;
  }, [alreadyPopedup]);

  return (
    <ParallaxProvider>
      <Container ref={containerRef}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Ancana - Tulum</title>
          <meta name={`robots`} content={`noindex, nofollow`} />
        </Helmet>
        <NavBar scrollTo={scrollTo} />

        <div id="hero">
          <HeroBannerSection scrollTo={scrollTo} />
        </div>
        <div id="property">
          <PropertiesSection listings={listings} scrollTo={scrollTo} />
        </div>
        <LoremSection />
        <div id="fractional">
          <FractionalSection />
        </div>
          <BenefitsSection />
        <div ref={observedRef}>
         <HowSection />
        </div>
        <WhyAncanaSection />
        <div id="contact">
          <ContactSection />
        </div>

        <GallerySection houses={listings} />
        <MentionsSection />

        <FaqSection />

        <Footer scrollTo={(name) => scrollToSection(name, containerRef)} />
      </Container>

      <ContactModal
        visible={popupVisible}
        handleClose={() => setPopupVisible(false)}
      />
    </ParallaxProvider>
  );
}

export default V2Page;
