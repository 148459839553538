import React from "react";
import styled from 'styled-components';
import Scrible from "../../images/collage/scrible1.inline.svg";
import H from "../text/H";
import { StaticImage } from "gatsby-plugin-image";
import ContactForm from "../ContactForm";
import P from "../text/P";
import B from "../text/B";
import { breakPoints } from "../../misc/consts";
import { Parallax } from "react-scroll-parallax";


const Container = styled.div`
  align-items: center;
  position: relative;
  z-index: 0;

  .bg-img {
    width: 100%;
    position: absolute;
  }
  .le-img {
    width: 100%;
    height: 600px;
    ${breakPoints.tablet} {
      height: 100%;
    }
  }
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(50,50,50,.3);
  backdrop-filter: blur(2px);
  position: absolute;
`;

const Content = styled.div`
  z-index: 2;

  height: 640px;
  ${breakPoints.tablet} {
    height: 39.2vw;
  }

  .stack {
    align-items: center;
    flex-direction: column;
    margin-bottom: 32px;
    ${breakPoints.tablet} {
      flex-direction: row;
      margin-bottom: 72px;
    }
  }

  .form {
    margin-top: 16px;
    ${breakPoints.tablet} {
      margin-top: 0;
    }
    .header {
      margin-bottom: 0;
    }
  }

  form {
    min-width: 300px;
    ${breakPoints.tablet} {
      width: 300px;
    }
    ${breakPoints.desktop} {
      width: 320px;
    }
    ${breakPoints.largeDesktop} {
      width: 400px;
    }
  }
`;

function ContactSection() {

  return (
    <Container>
      <div className="bg-img" style={{ zIndex: -1 }}>
        <Parallax speed={-6}>
          <StaticImage
            src="../../images/properties/saasil-garden-1.webp"
            alt="contact us hoouse background"
            quality={100}
            className="le-img"
          />
          <Overlay />
        </Parallax>
      </div>
      <Content className="marginal">
        <div className="h-stack gap-md mb-xxl full-height">
          <div className="h-center full-height mr-xxl tablet-and-up">
            <P color="white" className="fs-7 fw-600 contact-h text-shadow-5 mb-xxl">
              Cientos de personas ya descubrieron la <span className="underline">forma inteligente</span> de comprar <span className="underline">casa en Tulum</span>.
            </P>
            <H color="white" className="fs-10 text-shadow-3 underline">
              ¡Contáctanos ya!
            </H>
          </div>
          <div className="h-center full-height mobile-only">
            <P color="white" className="fs-5 fw-600 contact-h text-shadow-5 mb-xl center-text">
              Cientos de personas ya descubrieron la <span className="underline">forma inteligente</span> de comprar <span className="underline">casa en Tulum</span>.
            </P>
            <H color="white" className="fs-8 text-shadow-3 underline center">
              ¡Contáctanos ya!
            </H>
          </div>
          <div className="form h-center full-height">
            <ContactForm
              fields={['name', 'email', 'phone']}
              locale={'es'}
              className="shadow-1 corp-shadow-sm"
            />
          </div>
        </div>
      </Content>
    </Container>
  )
}

export default ContactSection;
