import React, { useState } from "react";
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import Input from "./Input";
import { ancanaPalette, bravoPalette, breakPoints } from "../misc/consts";
import Button from "./Button";
import ancanaAPI from "../api/ancanaAPI";


const Container = styled.div`
  color: ${ancanaPalette.text.primary};
  text-shadow: rgba(0, 0, 0, 0);
  background-color: white;
  padding: 16px;

  ${breakPoints.desktop} {
    padding: 24px;
  }

  .header {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 22px;
    text-align: center;

    ${breakPoints.desktop} {
      font-size: 20px;
    }
  }

  .feedback {
    font-size: 14px;
    margin-top: 16px;
    font-weight: 500;
    text-align: center;
  }
  .error {
    color: darkred;
  }
`;

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{4,5}$/
const schema = yup.object({
  name: yup.string().required('name required'),
  email: yup.string().email('email not valid').required('email required'),
  phone: yup.string().matches(phoneRegExp, 'phone not valid'),
}).required();

function ContactForm({ title, fields, locale = 'es', className='', cta='Solicitar información' }) {
  const { register, handleSubmit, formState: { errors }, reset } = useForm({ resolver: yupResolver(schema) });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const { t } = useTranslation();

  const onSubmit = data => {
    setSubmitting(true);
    const zipcode = '23400';
    const message = '(source: Tulum landing)';

    window.dataLayer = window.dataLayer || [];
    const ecData = {
      "email": data.email,
      "phone_number": data.phone,
    };
    window.dataLayer.push({
      'ecData': ecData
    });
    window.dataLayer.push({ 'event': 'formSubmitted' });

    ancanaAPI.post('submit_contact_form2', { ...data, zipcode, message }).then(res => {
      reset();
      setIsSubmitted(true);
      setSubmitting(false);
      // setTimeout(() => setIsSubmitted(false), 5000);
    }).catch(
      (e) => {
        setSubmitting(false);
        setErrorMsg(e.toString());
      }
    );
  };

  return (
    <Container className={className}>
      <div className="header">
        {title}
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        {fields.map(field => (
          <Input name={field} register={register} error={errors[field]} locale={locale} />
        ))}
        {!isSubmitted && (
        <Button btnStyle={{ width: '100%' }} color={bravoPalette.blue} loading={submitting} disabled={submitting || isSubmitted}>
          {cta}
        </Button>
        )}
      </form>

      {isSubmitted && (
        <div className="feedback">
          {t('Thank you for your interest in Ancana! Someone from our team will contact you shortly')}
        </div>
      )}
      {errorMsg !== '' && (
        <div className="feedback error">
          {errorMsg}
        </div>
      )}
    </Container>
  )
}

export default ContactForm;
