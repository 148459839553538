import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import CaretLeft from '../images/icons/caret-left.inline.svg';
import CaretRight from '../images/icons/caret-right.inline.svg';
import { ancanaPalette, breakPoints, debounce } from "../misc/consts";
import ShowWithAnimation from "./ShowWithAnimation";


const Container = styled.div`
  scrollbar-width: none;
  position: relative;

  ::-webkit-scrollbar {
    display: none;
  }

  flex-direction: row;

  ${breakPoints.tablet} {
    flex-direction: row;
    overflow: visible;
  }

  .scroll {
    flex-direction: row;
    width: 100%;
    margin: 0 auto;
    display: flex;
    overflow-x: scroll;
    overflow-y: visible;

    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }
    justify-content: flex-start;

    ${breakPoints.largeTablet} {
      justify-content: center;
    }

    ${breakPoints.desktop} {
      flex-direction: row;
      padding: 0 24px;
    }
  }

  .control {
    transition: opacity 0.4s ease;
    cursor: pointer;
    position: absolute;
    top: 35%;
    background-color: rgba(243, 243, 243, 0.95);
    z-index: 10;
    padding: 15px 10px;
    opacity: 1;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .33);
  }

  ${breakPoints.desktop} {
    flex-direction: row;
    overflow: visible;
    .control {
      top: 42%;
      background-color: rgba(243, 243, 243, 0.8);
      opacity: 0 !important;
      padding: 15px 20px;
    }
    :hover {
      .control {
        opacity: 0 !important;
      }
    }
  }

  .left {
    left: 6px;
    ${breakPoints.desktop} {
      left: 12px;
    }
  }

  .right {
    right: 6px;
    ${breakPoints.desktop} {
      right: 12px;
    }
  }
`

function Stack2({ children, step = 1, offset = -100, initialOffset = 0 }) {
  const [reachedLeft, setReachedLeft] = useState(true);
  const [reachedRight, setReachedRight] = useState(false);
  const containerRef = useRef(null);

  const scrollNext = () => {
    containerRef.current.scrollTo({
      left: containerRef.current.scrollLeft + (containerRef.current.scrollWidth / step) + offset + (containerRef.current.scrollLeft === 0 ? initialOffset : 0),
      behavior: 'smooth'
    });
  }

  const scrollPrev = () => {
    containerRef.current.scrollTo({
      left: containerRef.current.scrollLeft - (containerRef.current.scrollWidth / step) - offset,
      behavior: 'smooth'
    });
  }

  const handleScroll = (e) => {
    const totalWidth = e.target.scrollWidth - e.target.offsetWidth;
    if (totalWidth - e.target.scrollLeft > 5) {
      setReachedRight(false);
    } else {
      setReachedRight(true);
    }

    if (e.target.scrollLeft > 5) {
      setReachedLeft(false);
    } else {
      setReachedLeft(true);
    }
  };

  return (
    <Container>
      <ShowWithAnimation isMounted={!reachedLeft}>
        <div className="control left" onClick={scrollPrev}>
          <CaretLeft />
        </div>
      </ShowWithAnimation>
      <ShowWithAnimation isMounted={!reachedRight}>
        <div className="control right" onClick={scrollNext}>
          <CaretRight />
        </div>
      </ShowWithAnimation>
      <div className="scroll" ref={containerRef} onScroll={debounce(handleScroll)}>
        {children}
      </div>
    </Container>
  )
}

export default Stack2;
