import React, { useEffect, useState } from "react";
import styled from "styled-components";
import H from "../text/H";
import P from "../text/P";
import { bravoPalette, breakPoints, corporatePalette } from "../../misc/consts";
import B from "../text/B";
import { localizePrice } from "../../misc/utils";
import Button from "../Button";
import { Parallax, ParallaxBanner } from 'react-scroll-parallax';
import BlueBed from '../../images/icons/small/blueBed.inline.svg';
import BlueSquere from '../../images/icons/small/blueSquere.inline.svg';
import BlueBath from '../../images/icons/small/blueBath.inline.svg';
import CaretLeft from '../../images/icons/caret-left.inline.svg';
import CaretRight from '../../images/icons/caret-right.inline.svg';
import User from '../../images/icons/user.inline.svg';
import Stack2 from "../Stack2";
import InstaCarousel from "../InstaCarousel";


const Container = styled.div`
  .prop-row {
    padding: 0 12px;
    ${breakPoints.desktop} { padding: 0 32px; }
  }

  .caret-cont {
    position: absolute;
    top: 20%;
    cursor: pointer;
  }
  .caret-left {
    left: 12px;
    ${breakPoints.desktop} { left: 32px; }
  }
  .caret-right {
    right: 12px;
    ${breakPoints.desktop} { right: 32px; }
  }

  .current-listing {
    gap: 22px;
    ${breakPoints.tablet} { gap: 32px }
    ${breakPoints.desktop} { gap: 48px }
  }
`;

const CurrentImg = styled.div`
  flex: 1;
  position: relative;
  width: 100%;
  height: 80vw;
  ${breakPoints.tablet} { height: 300px }
  ${breakPoints.desktop} { height: 350px }

  .house-banner {
    height: 80vw;
    ${breakPoints.tablet} { height: 300px }
    ${breakPoints.desktop} { height: 350px }
  }

  .bulls {
    bottom: -6px;
  }
`;

const CurrentDesc = styled.div`
  flex: 1;
  padding: 0;
  justify-content: center;
`;

const HouseCard = styled.div`
  position: relative;
  cursor: pointer;
  // border: 1px solid;
  height: 318px;
  z-index: 0;
  margin: 0 16px;

  ${breakPoints.desktop} {
    height: 356px;
    margin: 0 32px;
  }
`;

const HBull = styled.div`
  background: ${bravoPalette.blue};
  width: 10px;
  height: 10px;
  border-radius: 10px;
  align-self: center;
  margin: 0 18px;
`;

const HBar = styled.div`
  height: 16px;
  width: 3px;
  background-color: ${bravoPalette.blue};
  margin: 0 16px;
`;

const Card = styled.div`
  position: absolute;
  background-color: white;
  box-shadow: 0 2px 7px rgba(0,0,0,.2);
  padding: 12px;

  width: 180px;
  height: 160px;
  top: 150px;
  left: 15px;

  ${breakPoints.desktop} {
    width: 225px;
    height: 175px;
    top: 175px;
    left: 17px;
  }
`;

const CardImg = styled.div`
  width: 250px;
  height: 175px;
  ${breakPoints.desktop} {
    width: 300px;
    height: 200px;
  }
`;

function PropertiesSection({ listings, scrollTo }) {
  const [currentListing, setCurrentListing] = useState(listings[0]);
  const spanishDescription = currentListing?.description_es || "";
  const [viewMore, setViewMore] = useState(false);

  useEffect(() => {
    if (listings.length > 0) {
      setCurrentListing(listings[0]);
    }
  }, [listings]);

  useEffect(() => {
    setViewMore(false);
  }, [currentListing]);

  if (!currentListing) return null;

  return (
    <Container className="white-bg">
      <div className="marginal">
        <div className="center mb-lg">
          <H style={{ fontSize: 30 }}>Casas en Tulum</H>
          <P className="center-text">La casa vacacional de tus sueños <br className="mobile-only" /> a un clic de distancia</P>
        </div>
        <div className="current-listing h-stack shadow-partner">
            <CurrentImg className="corp-shadow-lg">
              {/*
            <ParallaxBanner
              layers={[{ image: currentListing.preview_images[0], speed: -3 }]}
              className="house-banner"
            />*/}
            <InstaCarousel
              className="house-banner"
              images={currentListing.images.filter((el, idx) => idx % 2 === 0).slice(0,6)}
              showHandles
            />
          </CurrentImg>


          <CurrentDesc>
            <div className="mb-sm fw-600 fs-4-5">{currentListing.name}</div>
            <div style={{ flexDirection: 'row' }} className="mb-sm">
              <B>{localizePrice(currentListing.price.amount, currentListing.price.currency, 'es')}</B>
              <HBull />
              <B>{`1/${currentListing.characteristics.fractions} de la propiedad`}</B>
            </div>
            <div style={{ flexDirection: 'row' }} className="mb-lg">
              <div>{parseFloat(currentListing.characteristics.bedrooms)} Recámaras</div>
              <HBar />
              <div>{parseFloat(currentListing.characteristics.bathrooms)} Baños</div>
              <HBar />
              <div>{parseFloat(currentListing.characteristics.square_meters)} M2</div>
            </div>
            {viewMore ? (
              <P className="mb-lg fs-2-66">
                {spanishDescription}
              </P>
            ) : (
              <P className="mb-lg fs-2-66">
                {`${spanishDescription.substring(0, 450)}...`} <b style={{ cursor: 'pointer' }} onClick={() => setViewMore(true)}>Ver mas.</b>
              </P>
            )}
            <Button onClick={() => scrollTo('contact')}><User className="mr-sm" /> Contactar asesor</Button>
          </CurrentDesc>
        </div>
      </div>

      <div style={{ position: 'relative' }}>
        {/*
        <div className="caret-cont caret-left z-idx-3">
          <div className="shadow-1 corp-shadow-sm-rev" style={{ padding: '18px 12px' }}><CaretLeft /></div>
        </div>
        <div className="caret-cont caret-right z-idx-3">
          <div className="shadow-1 corp-shadow-sm" style={{ padding: '18px 12px' }}><CaretRight /></div>
        </div>*/}
        <Stack2
          className="h-stack-strict x-scroll gap-0 prop-row z-idx-0 shadow-partner"
          step={listings.length}
          offset={0}
          initialOffset={-48}
        >
          {console.log('>> listings', listings)}
          {listings.map(listing => (
            <HouseCard
              onClick={() => {
                setCurrentListing(listing);
                scrollTo('property', -140, false);
              }}
            >
              <CardImg style={{ background: `url(${listing.images[0]}) center center / cover no-repeat` }} />
              <Card
                selected={!!currentListing && listing.id === currentListing.id}
                className={!!currentListing && listing.id === currentListing.id ? 'corp-shadow-sm' : ''}
              >
                <H color={corporatePalette.green} className="fs-3 fw-600 fw-underline mb-sm">
                  {listing.name}
                </H>
                <P color={corporatePalette.blue} className="fs-2-66 fw-600">
                  {localizePrice(listing.price.amount, listing.price.currency, 'es')}
                </P>
                <P className="fs-2 mb-md">
                  1/{listing.characteristics.fractions} de la propiedad
                </P>
                <div className="h-stack-strict gap-sm mb-sm">
                  <div className="h-stack-strict gap-xs"><BlueBed />{parseFloat(listing.characteristics.bedrooms)}</div>
                  <div className="h-stack-strict gap-xs"><BlueSquere />{parseFloat(listing.characteristics.square_meters)}</div>
                  <div className="h-stack-strict gap-xs"><BlueBath />{parseFloat(listing.characteristics.bathrooms)}</div>
                </div>
                <P className="fs-2-66 underline">Ver detalles</P>
              </Card>
            </HouseCard>
          ))}
        </Stack2>
      </div>
    </Container>
  )
}

export default PropertiesSection;
